
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      paymentError: '',
      paymentErrorMessage: [],
      defaultErrorMessage: '- Please try an alternative payment method',
    }
  },
  computed: {
    parseErrorMessages() {
      return this.paymentErrorMessage.filter(
        (error) => error !== 'Payment failed.'
      )
    },
  },
  mounted() {
    this.$nuxt.$on('payment-error', (error, message) => {
      this.paymentError = error
      this.paymentErrorMessage.push(message)

      const contextData = [
        {
          name: error,
        },
      ]
      this.paymentErrorMessage.forEach((element) => {
        contextData.push({ name: element })
      })

      this.sendLog({
        level: 'error',
        message: this.paymentErrorMessage[0],
        context: [...contextData],
      })
    })
  },
  beforeDestroy() {
    this.paymentError = ''
    this.paymentErrorMessage = []
  },
  methods: {
    ...mapActions('ui', ['sendLog']),
  },
}
