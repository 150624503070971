
export default {
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    selectionValue: {
      type: [String, Number],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    change(e) {
      this.$emit('input', this.selectionValue)
    },
  },
}
