
import { mapActions, mapGetters } from 'vuex'

import ExpressPayment from '@/components/checkout/ExpressPayment'
import ExpressGooglePay from '@/containers/checkout/express/ExpressGooglePay'
import ExpressApplePay from '@/containers/checkout/express/ExpressApplePay'
import PaymentErrorBox from '@/components/PaymentErrorBox.vue'

import { cardPaymentMethod, expressPaymentMethods } from '@/plugins/enums'

export default {
  components: {
    ExpressPayment,
    ExpressGooglePay,
    ExpressApplePay,
    PaymentErrorBox,
  },
  props: {
    orderData: {
      type: Object,
      default: () => null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    shoppingCart: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      paymentMethod: expressPaymentMethods.cardExpress.value,
      cardPaymentMethod: expressPaymentMethods.cardExpress,
      expressPaymentMethods,
      baseFreeShippingLimit: parseFloat(process.env.BASE_FREE_SHIPPING_LIMIT),
      setShoppingCart: false,
    }
  },

  computed: {
    ...mapGetters('cart', ['total', 'totalPrice', 'items']),
    ...mapGetters('checkout', ['checkout']),

    bagHasOnlyGiftCard() {
      if (this.orderData) {
        return (
          this.orderData.orderProducts.filter((product) => {
            return product.has_shipping === true
          }).length === 0
        )
      } else {
        return false
      }
    },
    isExpressGoogleMethod() {
      return this.paymentMethod === expressPaymentMethods?.google?.value
    },
  },

  watch: {
    orderData: {
      handler() {
        this.paymentMethod = cardPaymentMethod.value
      },
      deep: true,
    },
    totalPrice: {
      handler() {
        this.paymentMethod = cardPaymentMethod.value
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('notification', ['addNotification']),
    ...mapActions('checkout', ['setSelectedPaymentMethod']),

    checkoutWithCard() {
      if (this.$auth.loggedIn) {
        this.$router.push('/checkout/info')
      } else {
        this.$router.push('/checkout/guest')
      }
      this.setSelectedPaymentMethod(6)
      this.$store.dispatch('ui/closeAll')
    },
    checkoutWithOther() {
      this.$router.push('/checkout/info')

      this.setSelectedPaymentMethod(2)

      this.$store.dispatch('ui/closeAll')
    },
    expressPurchaseCancel() {
      this.addNotification({
        message: this.$t('checkout.paymentCanceled'),
        duration: 15000,
      })
    },

    expressPurchaseSuccess() {
      this.$router.push(`/checkout/success/${this.orderData.order.id}`)
    },

    expressPurchaseError() {
      console.warn(this.$t('checkout.paymentFailed'))
    },
  },
}
