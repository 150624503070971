
import RadioButton from '@/components/RadioButton'
export default {
  components: {
    RadioButton,
  },
  props: {
    value: {
      type: Number,
      default: null,
    },
    selectionValue: {
      type: Number,
      default: null,
    },
    images: {
      type: Array,
      default: () => [],
    },
    text: {
      type: String,
      default: null,
    },
    buttonStyle: {
      type: String,
      default: null,
    },
    imageWidth: {
      type: String,
      default: '50px',
    },
  },
}
