
import { mapActions, mapGetters } from 'vuex'
import { expressPaymentMethods } from '~/plugins/enums'
import AuthenticationForm from '~/containers/checkout/AuthenticationForm'
import { JudoIframe, GPayConfigs } from '~/plugins/GPayConfigs'
// import { checkoutStep } from '~/plugins/gtm-helper'
import { initiateCheckout as fbInitiateCheckout } from '~/plugins/fb-pixel-helper'

const sdkUrl = process.env.JUDOPAY_WEB_SDK
const judoId = process.env.JUDOPAY_ID
const sandboxToken = process.env.JUDOPAY_TOKEN_SANDBOX
const liveToken = process.env.JUDOPAY_TOKEN_LIVE

export default {
  components: { AuthenticationForm },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    orderData: {
      type: Object,
      default: () => null,
    },
    hideBack: {
      type: Boolean,
      default: false,
    },
    // Use cart page design
    cart: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      authenticationData: null,
      judo: null,
      express: {},
      orderPayment: {},
      isLoaded: false,
      buttonCofiguration: {
        size: '42px',
        type: this.cart ? 'long' : 'short',
      },
    }
  },
  computed: {
    ...mapGetters('cart', {
      cartItems: 'items',
      cartTotalPrice: 'totalPrice',
      getMetaCategory: 'getMetaCategory',
    }),
    requestData() {
      return {
        orderProducts: this.items,
        orderPayment: {
          ...this.orderPayment,
          method: expressPaymentMethods.google.value,
        },
        session_id: this.$store.state.session.sessionId,
      }
    },
  },
  mounted() {
    this.mountJudoPay()
  },
  methods: {
    ...mapActions('notification', ['catchNotification', 'addNotification']),
    ...mapActions('cart', ['updateCartItemsResponseFromExpress']),
    ...mapActions('ui', [
      'toggleAddToBagFeedbackForAWhile',
      'doShowSider',
      'doHideSider',
      'expressLoaderModalSetter',
      'sendLog',
    ]),
    mountJudoPay() {
      const hasJudoPaySdk = !!document.querySelectorAll(
        'script[src*="judopay.com"]'
      ).length

      if (!hasJudoPaySdk) {
        const script = document.createElement('script')
        script.src = sdkUrl
        script.addEventListener('load', () => {
          console.log('JudoPay SDK is loaded')
          setTimeout(() => {
            this.setupGooglePayButton()
          }, 100)
        })
        document.head.appendChild(script)
        console.log('Try to add JudoPay SDK to DOM')
      } else {
        setTimeout(() => {
          this.setupGooglePayButton()
        }, 100)
      }
    },
    fakeFrameJudo() {
      this.judo.createCardDetails('judoframe', JudoIframe)
    },
    setupGooglePayButton() {
      const sandbox = false

      // eslint-disable-next-line no-undef
      this.judo = new JudoPay(
        sandbox ? sandboxToken : liveToken,
        sandbox // process.env.ENVIRONMENT !== 'production'
      )

      this.fakeFrameJudo()

      this.$axios
        .$post('payment/session', {
          session_id: this.$store.state.session.sessionId,
          express: true,
        })
        .then((response) => {
          this.express = response.data

          const handleSuccess = (result) => {
            console.info(result)
            this.isLoading = true
            this.expressLoaderModalSetter(false)

            this.sendLog({
              level: 'info',
              message: 'Google Pay Success',
              context: [],
            })

            if (result) {
              this.$set(this.orderPayment, 'googlePayWallet', result)
              this.onApprove()
            } else {
              this.$emit('cancel-purchase')
            }
          }
          const handleError = (error) => {
            this.expressLoaderModalSetter(false)
            console.error(error)
            const mess =
              error.judoDetails?.message || error.message || error.statusMessage
            if (mess === 'User cancelled.') return
            this.openSideGoogleError(mess)
            this.$emit('error', mess)
          }
          const openLoader = () => {
            this.expressLoaderModalSetter(true)
          }

          // payment Ref
          const pr = this.express.yourPaymentReference
          // consumer Ref
          const cr = `${
            this.$auth.user?.id || this.$store.state.session.sessionId
          }`
          // Payment Session - returns from Judo
          const ps = this.express.reference
          // Selected Shipping Price
          const sPrice = this.express.shipping_profile_rate_price.toFixed(2)
          // SubTotal of order
          const subTotal = this.express.subtotal.toFixed(2)
          // Total Amount of Order
          const total = (
            this.express.subtotal + this.express.shipping_profile_rate_price
          ).toFixed(2)
          // shipping Object - We need to pass shown to customers
          const sWithPrice = [
            {
              id: this.express.default_shipping_profile_rate_id.toString(),
              label: this.express.shipping_profile_rate_name,
              description: `${this.express.shipping_profile_rate_name} (${this.express.shipping_profile_rate_estimated})`,
              price: this.express.shipping_profile_rate_price,
            },
          ]

          // Get currency
          const currency = this.express.currency

          const googlePayConfiguration = GPayConfigs(
            sandbox,
            pr,
            cr,
            ps,
            sWithPrice,
            sPrice,
            subTotal,
            total,
            currency
          )
          googlePayConfiguration.onError = handleError
          googlePayConfiguration.onSuccess = handleSuccess
          googlePayConfiguration.onPaymentDataChanged = openLoader

          const gpay = this.judo.digitalWallets.getGooglePayButton(
            googlePayConfiguration
          )

          const vm = this
          gpay.addEventListener('click', function () {
            fbInitiateCheckout(vm.$fb, {
              total: vm.cartTotalPrice,
              items: vm.cartItems,
              metaCategory: vm.getMetaCategory,
            })
          })

          this.$refs.googlePay.appendChild(gpay)
          this.isLoaded = true
        })
        .catch((error) => {
          this.$emit('error', error)
        })
    },
    onApprove() {
      return this.$axios
        .$post('payment/expressPurchase', this.requestData)
        .then((responseData) => {
          const response = responseData.data
          if (response && response.success) {
            if (response.status === '3DAuth') {
              this.authenticationData = response
            } else {
              this.sendLog({
                level: 'info',
                message: 'Google Pay Success',
                context: [],
              })
              this.$emit('submit', response)
              this.$router.push(
                this.localePath(`/checkout/success/${response.orderId}`)
              )
            }
          }
        })
        .catch((error) => {
          // this.catchNotification(error)
          this.openSideGoogleError(error.response.data.message)
          this.$emit('error', error.response.data)
          this.$emit('error', error.response.data.message)
          this.isLoading = false
        })
    },
    async refreshGoogleButton() {
      await this.$refs.googlePay.removeChild(this.$refs.googlePay.firstChild)
      await this.mountJudoPay()
    },
    async openSideGoogleError(error) {
      await this.refreshGoogleButton()
      await this.doHideSider('cart')
      if (window.innerWidth > 1024) {
        await this.doShowSider('cart')
      }
      await this.$nuxt.$emit('payment-error', 'google', error)
    },
  },
}
