
import { mapActions, mapGetters } from 'vuex'
import { expressPaymentMethods } from '~/plugins/enums'
import AuthenticationForm from '~/containers/checkout/AuthenticationForm'
// import { checkoutStep } from '~/plugins/gtm-helper'
import { initiateCheckout as fbInitiateCheckout } from '~/plugins/fb-pixel-helper'

export default {
  components: { AuthenticationForm },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    orderData: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      authenticationData: null,
      isLoading: true,
      warningMessage: null,
      applePaySession: null,
      selectedShippingProfileRateId: null,
      express: {},
      orderPayment: {},
    }
  },
  computed: {
    ...mapGetters('cart', {
      cartItems: 'items',
      cartTotalPrice: 'totalPrice',
      getMetaCategory: 'getMetaCategory',
    }),

    requestData() {
      return {
        orderProducts: this.items,
        orderPayment: {
          ...this.orderPayment,
          selectedShippingProfileRateId: this.selectedShippingProfileRateId,
          method: expressPaymentMethods.apple.value,
        },
        session_id: this.$store.state.session.sessionId,
      }
    },
    selectedShippingProfileRate() {
      if (
        this.selectedShippingProfileRateId &&
        this.express.shippingProfileRates
      ) {
        return this.express.shippingProfileRates.find(
          (shippingProfileRate) =>
            shippingProfileRate.id.toString() ===
            this.selectedShippingProfileRateId
        )
      }

      return null
    },
    lineItems() {
      const lineItems = this.items
        ? this.items.map((cart) => ({
            label:
              cart.productVariant.variantTitle !== 'Default Title'
                ? cart.productVariant.variantTitle
                : '',
            amount: cart.price,
          }))
        : []

      if (this.selectedShippingProfileRate) {
        lineItems.push({
          type: 'final',
          label: this.selectedShippingProfileRate.shipping_profile.name,
          amount: this.express.shipping_profile_rate_price,
        })
      }

      return lineItems
    },
    total() {
      if (this.express && this.express.subtotal) {
        if (
          this.selectedShippingProfileRate &&
          this.express.shipping_profile_rate_price
        ) {
          return (
            this.express.subtotal + this.express.shipping_profile_rate_price
          )
        }

        return this.express.subtotal
      }

      return null
    },
    totalLineItem() {
      return {
        type: 'final',
        label: `YTC`,
        amount: this.total,
      }
    },
  },
  mounted() {
    this.setupApplePayButton()
  },
  methods: {
    ...mapActions('notification', ['catchNotification']),
    ...mapActions('ui', ['toggleSider']),
    setupApplePayButton() {
      // eslint-disable-next-line no-undef
      if (window.ApplePaySession) {
        // eslint-disable-next-line no-undef
        window.ApplePaySession.canMakePaymentsWithActiveCard(
          process.env.APPLEPAY_MERCHANT_ID
        )
          .then((canMakeExpressPayments) => {
            if (canMakeExpressPayments) {
              this.$axios
                .$post('payment/session', {
                  express: true,
                  session_id: this.$store.state.session.sessionId,
                })
                .then((response) => {
                  this.express = response.data
                  if (this.express.default_shipping_profile_rate_id) {
                    this.selectedShippingProfileRateId =
                      this.express.default_shipping_profile_rate_id.toString()
                  }

                  this.isLoading = false

                  try {
                    const vm = this
                    this.$refs.applePayButton.addEventListener(
                      'click',
                      function () {
                        try {
                          fbInitiateCheckout(vm.$fb, {
                            total: vm.cartTotalPrice,
                            items: vm.cartItems,
                            metaCategory: vm.getMetaCategory,
                          })
                        } catch (e) {
                          console.log('Error in fbInitiateCheckout', e)
                        }
                      }
                    )
                  } catch (error) {
                    console.log('error', error)
                  }

                  this.$refs.applePayButton.style.display = 'block'
                })
                .catch((error) => {
                  this.$sentry.captureException(error)

                  this.$emit('error', error)
                  this.catchNotification(error)
                })
            } else {
              this.markAsWarned('noActiveCard')
            }
          })
          .catch((error) => {
            this.$sentry.captureException(error)
            console.log('error', error)

            this.markAsWarned('notResponded')
          })
      } else {
        this.markAsWarned('notSupportedBrowserOrDevice')
      }
    },
    markAsWarned(message) {
      this.isLoading = false
      this.warningMessage = this.$t(`ExpressApplePay.warnings.${message}`)
    },
    onClickPay() {
      const paymentRequest = {
        currencyCode: this.express.currency,
        countryCode: 'GB',
        lineItems: this.lineItems,
        total: this.totalLineItem,
        supportedNetworks: ['amex', 'masterCard', 'visa'],
        requiredBillingContactFields: [
          'postalAddress',
          'email',
          'name',
          'phone',
        ],
        merchantCapabilities: [
          'supports3DS',
          'supportsCredit',
          'supportsDebit',
        ],
      }

      if (this.express.has_shipping) {
        paymentRequest.requiredShippingContactFields = [
          'postalAddress',
          'email',
          'name',
          'phone',
        ]
      }

      // eslint-disable-next-line no-undef
      this.applePaySession = new ApplePaySession(1, paymentRequest)

      this.applePaySession.onvalidatemerchant = (event) => {
        this.$axios
          .$post('payment/validateMerchant', {
            ...this.requestData,
            validationURL: event.validationURL,
          })
          .then((merchantSession) => {
            this.applePaySession.completeMerchantValidation(merchantSession)
          })
          .catch((e) => {
            this.$sentry.captureException(e)
            this.catchNotification(e)

            this.markAsWarned('merchantCouldNotValidate')

            // eslint-disable-next-line no-undef
            this.applePaySession.completePayment(
              window.ApplePaySession.STATUS_FAILURE
            )
          })
      }

      this.applePaySession.onpaymentmethodselected = (event) => {
        this.applePaySession.completePaymentMethodSelection(
          this.totalLineItem,
          this.lineItems
        )
      }

      this.applePaySession.onshippingmethodselected = (event) => {
        this.selectedShippingProfileRateId = event.shippingMethod.identifier
        this.applePaySession.completeShippingMethodSelection(
          window.ApplePaySession.STATUS_SUCCESS,
          this.totalLineItem,
          this.lineItems
        )
      }

      this.applePaySession.onshippingcontactselected = (event) => {
        const shippingMethods = this.express.has_shipping
          ? this.express.shippingProfileRates.map((shippingProfileRate) => ({
              identifier: shippingProfileRate.id.toString(),
              label: this.express.shipping_profile_rate_name,
              detail: this.express.shipping_profile_rate_estimated,
              amount: this.express.shipping_profile_rate_price,
            }))
          : []

        this.applePaySession.completeShippingContactSelection(
          window.ApplePaySession.STATUS_SUCCESS,
          shippingMethods,
          this.totalLineItem,
          this.lineItems
        )
      }

      this.applePaySession.onpaymentauthorized = (event) => {
        this.$set(this.orderPayment, 'applePayWallet', event.payment)
        this.onApprove()
          .then((response) => {
            // eslint-disable-next-line no-undef
            this.applePaySession.completePayment(
              window.ApplePaySession.STATUS_SUCCESS
            )
            this.$router.push(
              this.localePath(`/checkout/success/${response.orderId}`)
            )
          })
          .catch((error) => {
            this.$sentry.captureException(error)
            this.catchNotification(error, null, 30000)

            // eslint-disable-next-line no-undef
            this.applePaySession.completePayment(
              window.ApplePaySession.STATUS_FAILURE
            )
          })
      }

      this.applePaySession.oncancel = (event) => {
        this.$emit('cancel-purchase')
      }

      this.applePaySession.begin()
    },
    onApprove() {
      return new Promise((resolve, reject) => {
        this.$axios
          .$post('payment/expressPurchase', this.requestData)
          .then((responseData) => {
            const response = responseData.data
            if (response && response.success) {
              if (response.status === '3DAuth') {
                this.authenticationData = response
              } else {
                this.$emit('submit', response)
                resolve(response)
                // this.$router.push(
                //   this.localePath(`/checkout/success/${response.orderId}`)
                // )
              }
            }
          })
          .catch((error) => {
            this.$sentry.captureException(error)

            const message =
              error.response.data.errors?.orderPayment?.[0] ||
              error.response.data.errors?.['orderPayment.applePayWallet']?.[0]
            // this.catchNotification(error)
            this.openSideAppleError(message)
            this.$emit('error', error.response.data.message)
            this.isLoading = false
            reject(error)
          })
      })
    },
    async openSideAppleError(message) {
      await this.$nuxt.$emit('payment-error', 'apple', message || null)
    },
  },
}
