
import { expressPaymentMethods, paymentMethods } from '@/plugins/enums'
import PaymentMethodRadio from '@/components/checkout/PaymentMethodRadio'
import { mapGetters } from 'vuex'

export default {
  components: { PaymentMethodRadio },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      paymentThreshold: +process.env.EXPRESS_PAYMENT_THRESHOLD,
    }
  },
  computed: {
    ...mapGetters('cart', ['totalPrice']),
    expressPaymentMethods() {
      if (process.browser) {
        const canUseApplePay = window.ApplePaySession // this.$device.isIos || this.$device.isMacOS // isSafari
        // The Apple Pay JS API is available.

        if (!canUseApplePay) {
          delete expressPaymentMethods.apple
        }
      }
      if (this.$store.state.settings.currency !== 'GBP') {
        const newExpressPayment = {
          cardExpress: expressPaymentMethods.cardExpress,
          other: expressPaymentMethods.other,
        }
        return Object.keys(newExpressPayment).map((x) => ({
          name: this.$t(`expressPaymentMethods.${x}`),
          image: newExpressPayment[x].image,
          key: newExpressPayment[x].key,
          value: newExpressPayment[x].value,
        }))
      } else {
        return Object.keys(expressPaymentMethods).map((x) => ({
          name: this.$t(`expressPaymentMethods.${x}`),
          image: expressPaymentMethods[x].image,
          key: expressPaymentMethods[x].key,
          value: expressPaymentMethods[x].value,
        }))
      }
    },
  },
  methods: {
    change(value) {
      if (this.value === value) {
        return this.$emit('input', null)
      }
      this.$emit('input', value)
    },
  },
}
