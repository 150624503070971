const judoId = process.env.JUDOPAY_ID
const GMerchant = process.env.GOOGLEPAY_MERCHANT_ID

export const GPayConfigs = (
  sandbox,
  pr,
  cr,
  paymentSession,
  shipping,
  shippingPrice,
  subTotal,
  total,
  currency
) => {
  const getGoogleTransactionInfo = () => {
    return {
      displayItems: [
        {
          label: 'Subtotal',
          type: 'SUBTOTAL',
          price: subTotal,
        },
      ],
      currencyCode: currency,
      countryCode: 'GB',
      totalPriceStatus: 'FINAL',
      totalPriceLabel: 'Total',
      totalPrice: total,
    }
  }
  const getGoogleDefaultShippingOptions = () => {
    return {
      defaultSelectedOptionId: shipping[0].id,
      shippingOptions: shipping.map((x) => {
        return {
          id: x.id.toString(),
          label: x.label,
          description: x.description,
        }
      }),
    }
  }
  const getShippingCosts = (id) => {
    const selectedShipping = shipping.find((o) => o.id === id)
    return selectedShipping.price
  }
  const calculateNewTransactionInfo = (shippingOptionId) => {
    const newTransactionInfo = getGoogleTransactionInfo()

    const shippingCost = getShippingCosts(shippingOptionId)
    newTransactionInfo.displayItems.push({
      type: 'LINE_ITEM',
      label: 'Shipping cost',
      price: shippingCost.toFixed(2),
      status: 'FINAL',
    })

    let totalPrice = 0.0
    newTransactionInfo.displayItems.forEach(
      (displayItem) => (totalPrice += parseFloat(displayItem.price))
    )
    newTransactionInfo.totalPrice = totalPrice.toFixed(2)

    console.log('newTransactionInfo', newTransactionInfo)

    return newTransactionInfo
  }
  const onPaymentDataChanged = (intermediatePaymentData) => {
    return new Promise(function (resolve, reject) {
      const shippingOptionData = intermediatePaymentData.shippingOptionData
      const paymentDataRequestUpdate = {}

      if (intermediatePaymentData.callbackTrigger === 'INITIALIZE') {
        paymentDataRequestUpdate.newShippingOptionParameters =
          getGoogleDefaultShippingOptions()
        const selectedShippingOptionId =
          paymentDataRequestUpdate.newShippingOptionParameters
            .defaultSelectedOptionId
        paymentDataRequestUpdate.newTransactionInfo =
          calculateNewTransactionInfo(selectedShippingOptionId)
      } else if (
        intermediatePaymentData.callbackTrigger === 'SHIPPING_OPTION'
      ) {
        paymentDataRequestUpdate.newTransactionInfo =
          calculateNewTransactionInfo(shippingOptionData.id)
      }

      resolve(paymentDataRequestUpdate)
    })
  }

  const allowedCardNetworks = ['AMEX', 'MASTERCARD', 'VISA']
  const allowedAuthMethods = ['PAN_ONLY']
  const baseCardPaymentMethod = {
    type: 'CARD',
    parameters: {
      allowedAuthMethods,
      allowedCardNetworks,
      billingAddressRequired: true,
      billingAddressParameters: {
        format: 'FULL',
        phoneNumberRequired: true,
      },
    },
  }

  return {
    judoId,
    yourPaymentReference: pr,
    yourConsumerReference: cr,
    paymentSession,
    amount: parseFloat(total),
    environment: sandbox ? 'TEST' : 'PRODUCTION',
    countryCode: 'GB',
    currency,
    transactionMode: 'payment',
    domainName: 'www.rydale.com',
    buttonStyle: {
      type: 'buy',
      color: 'default',
      sizeMode: 'fill',
      locale: 'en',
    },
    paymentRequest: {
      apiVersion: 2,
      apiVersionMinor: 0,
      merchantInfo: {
        merchantId: sandbox ? judoId : GMerchant,
        merchantName: 'Yorkshire Trading Company',
      },
      transactionInfo: getGoogleTransactionInfo(),
      allowedPaymentMethods: [baseCardPaymentMethod],
      shippingAddressRequired: true,
      shippingAddressParameters: {
        phoneNumberRequired: true,
      },
      shippingOptionRequired: true,
      shippingOptionParameters: getGoogleDefaultShippingOptions(),
      callbackIntents: ['SHIPPING_OPTION'],
    },
    onPaymentDataChanged,
  }
}

export const JudoIframe = {
  iframe: {
    language: 'en',
    errorFieldId: 'judopay__errors',
    showCardTypeIcons: true,
    cardTypeIconRight: '10px',
    cardTypeIconTop: '-2px',
    backgroundColor: '#ffffff;', // $cardWhite
    layout: 'compact',
    enabledPaymentMethods: ['CARD'],
    defaultCountryCode: 'US',
    isCountryAndPostcodeVisible: false,
    isCardHolderNameVisible: true,
    errorsDisplay: 'HIDE_UNDER_FIELDS',
    disableUnderline: false,
    styles: {
      default: {
        field: {
          fontFamily: "'Roboto', sans-serif",
          fontSize: '16px',
          backgroundColor: 'white',
          color: '#2b2b2c',
          borderTop: 'solid 1px #c3c4c6',
          borderRight: 'solid 1px #c3c4c6',
          borderLeft: 'solid 1px #c3c4c6',
          borderBottom: 'solid 1px #c3c4c6',
          margin: '0',
          padding: '8px 8px 4px 8px',
        },
        label: {
          color: '#2b2b2c',
          fontFamily: "'Roboto', sans-serif",
          opacity: '0.7',
          height: '16px',
          fontSize: '12px',
          display: 'inline-block',
          transition: 'opacity 0.6s',
          margin: '2px 0 0 8px',
          position: 'relative',
          bottom: '-10px',
          zIndex: '100',
        },
      },
      focus: {
        field: {
          backgroundColor: 'white',
          color: '#2b2b2c',
          padding: '8px 8px 4px 8px',
          fontSize: '16px',
        },
        label: {
          opacity: '0',
        },
      },
      error: {
        field: {
          backgroundColor: '#fff5f5',
          color: '#c70000',
          padding: '8px 8px 4px 8px',
        },
        label: {
          opacity: '0',
        },
      },
      valid: {
        field: {
          backgroundColor: 'white',
          color: '#2b2b2c',
          padding: '8px 8px 4px 8px',
        },
        label: {
          opacity: '0',
        },
      },
    },
  },
}

export default GPayConfigs
