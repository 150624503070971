
export default {
  props: {
    authentication: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      authenticationData: this.authentication,
    }
  },
  computed: {
    callbackUrl() {
      return `${window.location.origin}/checkout/callback/${this.authenticationData.orderPaymentId}`
    },
  },
  mounted() {
    console.log('authenticationData', this.authenticationData)
    setTimeout(() => {
      this.$refs.authenticationForm.submit()
    }, 100)
  },
}
